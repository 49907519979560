import * as React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'

import './style.scss'

const DidYouKnow: React.FC = () => (
  <StaticQuery
    query={graphql`
      query DidYouKnowImageQuery {
        chartImage: file(relativePath: { eq: "images/plant-based-chart.png" }) {
          size
          childImageSharp {
            fluid {
              src
              sizes
            }
          }
        }
        groceryImage: file(relativePath: { eq: "images/Ingredients.png" }) {
          size
          childImageSharp {
            fluid {
              src
              sizes
            }
          }
        }
        recipeImage: file(relativePath: { eq: "food/tahini_quinoa.png" }) {
          size
          childImageSharp {
            fluid {
              src
              sizes
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <section id="did-you-know" className="bg-info-secondary py-4">
          <div className="container">
            <div className="row d-flex flex-column-reverse flex-md-row">
              <div className="col-12 col-md-5 d-flex flex-column justify-content-center">
                <h2 className="display-1 font-weight-bold text-center text-sm-left">Why plants?</h2>
                <p className="pt-3">
                  We believe that a great diet should keep you healthy <em className="text-secondary font-weight-bold">and</em> be sustainable.
                  Eating less meat and dairy not only reduces your risk for many chronic diseases, it lowers carbon emissions too.
                </p>
                <p>That’s a serious win-win.</p>
              </div>
              <div className="col-12 col-md-7">
                <img className="img-fluid img-box-shadow bowl" alt="Eating Plants" src={data.chartImage.childImageSharp.fluid.src} />
                <p className="pt-3 small text-secondary">source: <a className="text-secondary" target="_blank" href="https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0165797">Aleksandrowicz et al, PLoS One</a></p>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container py-5">
            <div className="row d-flex">
              <div className="col-12 text-center mb-4">
                <h2 className="font-weight-bold display-1">Get Cooking</h2>
              </div>
              <div className="col-12 col-md-6 text-center">
                <div className="my-md-5 my-3 p-5 bg-dark">
                  <Link className="mt-4 mb-5 text-primary" to="/shop-all-recipes">
                    <h3>Shop Recipes</h3>
                    <img className="img-fluid" alt="Eating Plants" src={data.recipeImage.childImageSharp.fluid.src} />
                  </Link>
                </div>
              </div>
              <div className="col-12 col-md-6 text-center">
                <div className="my-md-5 my-3 p-5 bg-dark">
                  <Link className="mt-4 mb-5 text-primary" to="/plantry">
                    <h3>Shop The Plantry</h3>
                    <img className="img-fluid" alt="Eating Plants" src={data.groceryImage.childImageSharp.fluid.src} />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )}
  />
)

export default DidYouKnow
