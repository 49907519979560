import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'

import './style.scss'

const Jumbotron: React.FC = () => {

  return (
    <StaticQuery
      query={graphql`
        query JumbotronImageQuery {
          groceryImage: file(relativePath: { eq: "images/Ingredients.png" }) {
            size
            childImageSharp {
              fluid {
                src
                sizes
              }
            }
          }
        }
      `}
      render={data => (
        <div className="section header bg-light">
          <div className="container bg-light">
            <div className="header-index jumbotron bg-light mb-0 pb-1">
              <div className="row">
                <div className="col-12 col-md-5">
                  <img className="img-fluid bowl" alt="Eating Plants" src={data.groceryImage.childImageSharp.fluid.src} />
                </div>
                <div className="col-12 col-md-7">
                  <h1 className="font-weight-bold display-1">
                    Less time planning and more time cooking
                  </h1>
                  <h4>
                    Everything you need to make tasty plant-based meals at home
                  </h4>
                  <Link className="btn btn-lg btn-primary mt-4 mb-5" to="/shop-all-recipes">Start Shopping</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    />)
}

export default Jumbotron
