import * as React from 'react'

import Jumbotron from '../components/homepage/Jumbotron'
import AboutTheStore from '../components/homepage/AboutTheStore'
import EmailCollection from '../components/shared/EmailCollection'
import ComingSoon from '../components/homepage/ComingSoon'
import WhereWeDeliver from '../components/homepage/WhereWeDeliver'
import DidYouKnow from '../components/homepage/DidYouKnow'
import Testimonials from '../components/homepage/Testimonials'
import Layout from '../layouts'

import './index.scss'

const IndexPage = () => (
  <Layout>
    <Jumbotron />
    <EmailCollection />
    <Testimonials />
    <ComingSoon />
    <WhereWeDeliver />
    <AboutTheStore />
    <DidYouKnow />
  </Layout>
)

export default IndexPage
