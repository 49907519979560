import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

const WhereWeDeliver: React.FC = () => (
  <StaticQuery
    query={graphql`
      query {
        deliveryImage: file(relativePath: { eq: "images/delivery_area.png" }) {
          size
          childImageSharp {
            fluid {
              src
              sizes
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <div className="section bg-info overflow-hidden">
          <div className="py-2 d-flex">
            <marquee>
              <p className="mb-0 font-weight-bold display-4">FREE delivery for a limited time! ⚡ FREE delivery for a limited time! ⚡ FREE delivery for a limited time! ⚡ FREE delivery for a limited time! ⚡ FREE delivery for a limited time! ⚡ FREE delivery for a limited time! ⚡ FREE delivery for a limited time! ⚡ FREE delivery for a limited time! ⚡ FREE delivery for a limited time! ⚡ FREE delivery for a limited time! ⚡ FREE delivery for a limited time! ⚡ FREE delivery for a limited time! ⚡ FREE delivery for a limited time! ⚡ FREE delivery for a limited time! ⚡ FREE delivery for a limited time! ⚡ FREE delivery for a limited time! ⚡ FREE delivery for a limited time! ⚡ FREE delivery for a limited time! ⚡ FREE delivery for a limited time! ⚡ FREE delivery for a limited time! ⚡ FREE delivery for a limited time! ⚡ FREE delivery for a limited time! ⚡ FREE delivery for a limited time! ⚡ FREE delivery for a limited time! ⚡ FREE delivery for a limited time! ⚡ FREE delivery for a limited time! ⚡ FREE delivery for a limited time! ⚡ FREE delivery for a limited time! ⚡ FREE delivery for a limited time! ⚡ FREE delivery for a limited time! ⚡ FREE delivery for a limited time! ⚡ FREE delivery for a limited time! ⚡ FREE delivery for a limited time! ⚡ FREE delivery for a limited time! ⚡ FREE delivery for a limited time! ⚡ FREE delivery for a limited time!</p>
            </marquee>
          </div>
        </div>
        <div className="section">
          <div className="container py-5">
            <div className="row">
              <div className="col-12 col-md-8">
                <img className="img-fluid" alt="Delivery Area" src={data.deliveryImage.childImageSharp.fluid.src} />
              </div>
              <div className="col-12 col-md-4 pt-3 pt-md-0 d-flex flex-column justify-content-center">
                <h2 className="font-weight-bold">Where we deliver</h2>
                <p>Currently delivering Tue/Thur/Sun to the west-side of Los Angeles.</p>
                <p className="small smaller">90232, 90025, 90291, 90404, 90402, 90403, 90401, 90405, 90064, 90066, 90292, 90034, 90230, 90035</p>
              </div>
            </div>
          </div>
        </div>
      </>
    )}
  />
)

export default WhereWeDeliver
