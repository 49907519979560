import * as React from 'react'
import { Link } from 'gatsby'
import { StaticQuery, graphql } from 'gatsby'
import Slider from 'react-slick';

import './style.scss'

const ComingSoon: React.FC = () => {

  const settings = {
    dots: false,
    infinite: true,
    fadeIn: false,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <StaticQuery
      query={graphql`
      query HompageRecipes {
        allContentfulRecipe(filter: {isHomepage: {eq: true}}) {
          nodes {
            mainImage {
              title
              fixed(width: 800) {
                src
              }
            }
            slug
            isHomepage
            homepageDescription {
              childMarkdownRemark {
                html
              }
            }
            name
          }
        }
      }
    `}

      render={data => {
        return (
          <section id="coming-soon" className="bg-dark pt-5">
            <div className="container">
              <div className="row d-flex justify-content-center mt-4 mt-md-0">
                <div className="col-12">
                  <div className="mx-auto text-lg-center">
                    <h2 className="font-weight-bold">Featured recipes</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="container position-relative">
              <Slider {...settings}>
                {data.allContentfulRecipe.nodes.map((recipe, index) => {
                  return (
                    <div key={`slider-${index}`}>
                      <div className="row position-relative food-row pb-0">
                        <div className="col-12 col-lg-5 offset-lg-1 d-flex">
                          <div className="mt-5">
                            <h3 className="display-1">{recipe.name}</h3>
                            <div dangerouslySetInnerHTML={{ __html: recipe.homepageDescription.childMarkdownRemark.html }} />
                            <div className="d-block d-lg-none col-lg-6 text-center">
                              <img className="img-fluid quinoa-bowl mx-auto" alt={recipe.mainImage.title} src={recipe.mainImage.fixed.src} />
                            </div>
                            <Link className="btn btn-lg btn-primary mt-3 mb-5 d-block w-75" to={`/recipes/${recipe.slug}`}>
                              View Recipe
                            </Link>
                          </div>
                        </div>
                        <div className="d-none d-lg-block col-lg-6 text-center">
                          <img className="img-fluid quinoa-bowl mx-auto" alt={recipe.mainImage.title} src={recipe.mainImage.fixed.src} />
                        </div>
                      </div>
                    </div>
                  )
                })}
              </Slider>
            </div>
          </section >
        )
      }

      }
    />
  )
}
export default ComingSoon
