import * as React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'

const AboutTheStore: React.FC = () => (
  <StaticQuery
    query={graphql`
      query {
        usImage: file(relativePath: { eq: "images/RomaineSalad.png" }) {
          size
          childImageSharp {
            fluid {
              src
              sizes
            }
          }
        }
      }
    `}
    render={data => (
      <div className="section bg-light py-5">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6">
              <img className="img-fluid bowl" alt="Eating Plants" src={data.usImage.childImageSharp.fluid.src} />
            </div>
            <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
              <h2 className="font-weight-bold pt-4">More plants to more people</h2>
              <br />
              <p>
                ELM offers 100% plant-based recipes for anyone who wants to turn meatless mondays into meatless most days... or maybe even meatless everyday!
              </p>
              <p>
                We care about people over perfection so wherever you’re at in your food journey we’re here for you.
              </p>
              <Link to="/about">More about us</Link>
            </div>
          </div>
        </div>
      </div>
    )}
  />
)

export default AboutTheStore
