import React, { useState, createRef } from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'

const EmailCollection: React.FC = () => {
  let emailInput = createRef();
  const handleSubmit = async (event) => {
    event.preventDefault()

    const email = emailInput.current.value
    const result = await addToMailchimp(email)
    console.log(result)
    setEmailValid(result.result == "error")
    setEmailMessage(result.msg)
  }

  let [emailMessage, setEmailMessage] = useState("&nbsp");
  let [emailValid, setEmailValid] = useState(false);

  return (
    <section className="position-relative" style={{ backgroundColor: '#e8e8e2' }}>
      <div className="container py-5">
        <div className="row">
          <div className="col-12 col-md-10 col-xl-8 mx-auto">
            <h2 className="font-weight-bold py-3">Stay in the know</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-10 col-xl-8 mx-auto bg-">
            <form onSubmit={handleSubmit} className="mailchimp-collection-form">
              <h4 className="font-weight-light pb-3 d-none d-sm-block">Sign up for our email list to get updates, our favorite recipes, and an occasional plant-based pun.</h4>
              <div className="input-group input-group-lg">
                <input type="email" placeholder="Email address" ref={emailInput} name="email" className="col-12 col-sm-8" />
                <div className="input-group-append col-12 col-sm-4 mt-3 mt-sm-0 mx-auto px-0 px-sm-3">
                  <input type="submit" className="btn btn-lg btn-primary btn-block px-3  submit-email" />
                </div>
              </div>
              <p dangerouslySetInnerHTML={{ __html: emailMessage }} className={`${emailValid ? "text-warning mb-0 small" : "text-success mb-0 small"}`} />
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default EmailCollection
